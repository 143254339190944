@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































































































































































































@import '@/assets/scss/_variables.scss';

.theme--dark.v-label--is-disabled,
.v-text-field.v-text-field--solo .v-label {
  color: #fff;
}

.ProvidersHistoryTable_row-container {
  width: max-content;
  overflow-x: scroll;
  max-width: 100%;

  @media only screen and (min-width: $tablet-width) {
    width: auto;
    overflow: auto;
  }
}

.ProvidersHistoryTable_filters {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &--start {
      justify-content: flex-start;
    }
  }

  .ProvidersHistoryTable_filter {
    margin: 10px 0;

    @media only screen and (min-width: $tablet-width) {
      margin: 0;
      flex: 0 1 calc(25% - 6px);
    }

    &--relative {
      position: relative;
    }
  }

  .ProvidersHistoryTable_filter-provider {
    flex: 0 1 50%;

    @media screen and (min-width: 768px) {
      margin-right: 10px;
    }
  }

  .ProvidersHistoryTable_filter-search {
    flex: 0 1 50%;

    .v-input.v-input--dense.v-text-field .v-input__control .v-input__slot {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}

.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    width: 100%;
    margin-bottom: 8px;

    .v-skeleton-loader__text {
      height: 50px;
    }
  }
}

.ProvidersHistoryTable_filter-skeleton.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    height: 69px;

    .v-skeleton-loader__text {
      height: 69px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
