@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




















.ProviderHistoryDetails_root {
  border: 2px solid #213b57;
  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ProviderHistoryDetails_wrapper {
  padding: 19px 15px 19px 15px;
  font-size: 14px;
  text-align: left;
}
