@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';

.ProvidersHistory_pagination {
  margin-top: 10px;

  .v-pagination {
    li {
      .v-pagination__item {
        background: transparent !important;
        color: #fff;
        box-shadow: none;
        font-size: 18px;
        outline: none;
        min-width: 20px;
        &--active {
          background-color: transparent !important;
          color: #2af3f3;
        }
      }
      .v-pagination__navigation {
        outline: none;
        width: 38px;
        height: 38px;
        background-color: transparent !important;
        background: rgba(42, 243, 243, 0.1) !important;
        box-shadow: none;
        transition: 0.1s all ease;
        &:hover {
          background: #2af3f3 !important;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
          .v-icon {
            color: #2d4d70;
          }
        }
      }
    }
  }
}
