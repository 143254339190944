@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































































































@import '@/assets/scss/_variables.scss';

.ProvidersHistoryRow_status {
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #5abc67;
  padding: 4px 8px;
  border-radius: 2px;
  background: rgba(#5abc67, 0.1);
  text-transform: lowercase;
  margin-left: auto;
  vertical-align: top;

  &--pending {
    background: rgba(#2af3f3, 0.1);
    color: #2af3f3;
  }
  &--failed {
    background: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
  &--expired {
    background: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
  &--cancelled {
    opacity: 0.6;
    background: #fff;
    color: #5abc67;
  }
}

.roundArrowBtn {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #2af1f1;
  background: rgba(#2af1f1, 0.21);
  position: relative;

  svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
  &--expanded {
    svg {
      transform: translate(50%, -50%) rotate(-180deg);
    }
  }
  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.round-arrow-btn-cancel {
  svg {
    width: 7px;
    height: 5px;
    transform: rotate(180deg);
  }
}
